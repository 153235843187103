import React from "react"
import WatermarkSVG from "./svgs/WatermarkSVG"

export default function LandingPageHero({ buttonLinks }) {
  const horizontalButtonLinks = buttonLinks.map(link => {
    return (
      <div className="d-inline mx-1 my-2 my-md-0">
        <a
          className="btn btn-outline-light btn-lg"
          href={link.href}
          role="button"
        >
          <i className={link.iconClass}></i>
          <span className="pl-2">{link.name}</span>
        </a>
      </div>
    )
  })

  const verticalButtonLinks = buttonLinks.map(link => {
    return (
      <div className="col-12 mx-1 my-2 my-md-0">
        <a
          className="btn btn-outline-light btn-lg"
          href={link.href}
          role="button"
        >
          <i className={link.iconClass}></i>
          <span className="pl-2">{link.name}</span>
        </a>
      </div>
    )
  })

  return (
    <div className="jumbotron jumbotron-fluid text-center shadow px-5 pb-4">
      <WatermarkSVG />
      <div className="heroToAnimate">
        <h1 className="d-none d-md-block display-1">
          Hi, I’m Vince. <span className="point-down"></span>
        </h1>
        <h1 className="d-none d-sm-block d-md-none display-4">
          Hi, I’m Vince.
        </h1>
        <h1 className="d-block d-sm-none">Hi, I’m Vince.</h1>
        <p className="lead">
          I'm a software engineer and composer / audio designer based out of the
          Seattle area.
        </p>
        <hr className="my-4 mx-3" />
        <p className="follow">Check out some of my work!</p>
        <div className="container d-flex justify-content-center">
          <div className="d-none d-md-block pb-md-4 pt-md-3">
            {horizontalButtonLinks}
          </div>
          <div className="row d-md-none">{verticalButtonLinks}</div>
        </div>
      </div>
    </div>
  )
}
